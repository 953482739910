import React from 'react';
import { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ChakraProvider } from '@chakra-ui/react';
import initLogrocket from '@/src/lib/initLogrocket';
import '@/src/lib/initSentry';
import theme from '@/src/shared/config/theme';
import { Provider } from 'next-auth/client';
import StackedLayout from '@/src/shared/components/StackedLayout';
import Head from 'next/head';
import '@/src/styles/global.css';
import { enableAllPlugins } from 'immer';

/** Init Immer */
enableAllPlugins();

/** Initialize LogRocket on client only, with correct environment */
initLogrocket();

/** NextJS Router vents for integrating progress bar across pages */
Router.events.on('routeChangeStart', () => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// TODO: componentDidCatch, logrocket, sentry
function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Matternet App Suite</title>
      </Head>
      <ChakraProvider theme={theme}>
        <Provider session={pageProps.session}>
          <StackedLayout>
            {/* Note, the Navbar is coming from Apollo */}
            <Component {...pageProps} />
          </StackedLayout>
        </Provider>
      </ChakraProvider>
    </>
  );
}

export default App;
